import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import LikeIcon from "../icons/Like";
import { Utils } from "../../utils";
import ChatsIcon from "../icons/Chats";
import Pinned from "../icons/Pinned";
import DOMPurify from "dompurify";

const getCleanHtml = function (details) {
    const decodedDetails = atob(details);
    const decodedHtml = DOMPurify.sanitize(decodedDetails, {
        USE_PROFILES: { html: true },
    });
    const cleanHtml = decodeURIComponent(decodedHtml);
    return cleanHtml;
}

const PostPreview = ({ community, forum, isPinned = false }) => {
    const navigate = useNavigate();

    const media = isPinned ? (forum?.pinned_post?.media ?? "") : (forum?.latest_post?.media ?? "");
    const commentsCount = isPinned ? (forum?.pinned_post?.count_of_replies ?? 0) : (forum?.latest_post?.count_of_replies ?? 0); 
    const likesCount = isPinned ? (forum?.pinned_post?.likes?.length ?? 0) : (forum?.latest_post?.likes?.length ?? 0);
    const postDate = isPinned ? (forum?.pinned_post?.createdAt??"") : (forum?.latest_post?.createdAt ?? "");  
    const by = isPinned ? (forum?.pinned_post?.user?.username ?? "") : (forum?.latest_post?.user?.username ?? ""); 
    const byId = isPinned ? (forum?.pinned_post?.user?._id ?? "") : (forum?.latest_post?.user?._id ?? "");
    const postId = isPinned ? forum?.pinned_post?._id : forum?.latest_post?._id;

    return (
        <>
            {/* pinned post details start */}
            {isPinned &&
                <a
                    className="flex flex-col w-full max-w-[calc(100vw-30px)] md:max-w-full rounded-md p-2 bg-slate-50 hover:bg-purple-200/30"
                    onClick={(e) => { e.stopPropagation(); navigate(`/community/${community._id}/forum/${forum._id}/post/${postId}`) }}
                >
                    <div className="text-xs mb-2 flex flex-row items-center">
                        <Pinned className="w-[15px] mr-1 text-sm text-red-600" />
                        Pinned Post By :
                        <a
                            className="text-purple-800 text-underline pl-1 cursor-pointer"
                            onClick={(e) => { e.stopPropagation(); navigate(`/profile/${byId}`)}} >
                            {by}
                        </a>
                        <span className="px-2">•</span>
                        <span className="text-xs ml-2x text-gray-600 pt-1 md:pt-0">
                             {/* {postDate} */}
                            <ReactTimeAgo date={postDate} locale="en-US" />
                        </span>
                    </div>
                    <div className="flex flex-row w-full max-w-full  md:truncate text-sm  text-purple-900 font-bold" >
                        {isPinned ? forum?.pinned_post?.title : forum?.latest_post?.title}
                    </div>
                    <div className="line-clamp-3 w-full max-w-full  " dangerouslySetInnerHTML={{ __html: getCleanHtml(isPinned ? (forum.pinned_post?.details ?? "") : (forum.latest_post?.details ?? "")) }}></div>

                    {(media && media.length > 0) &&
                        <div className="pt-2">
                            <img
                                className="inline-block  w-full max-w-full rounded-md "
                                src={media}
                                alt=""
                            />
                        </div>
                    }
                    <div className="flex flex-row items-center pt-2 text-[#6b7280]">
                        <LikeIcon className={'text-gray-500 md:w-3'} />
                        <span className="text-sm md:text-xs ml-1 mr-4 md:mr-2 text-gray-600">
                            {Utils.comma(likesCount)}
                        </span>
                        <ChatsIcon fillColor={'#6b7280'} strokeColor={'#ffffff'} className={'w-5 md:w-4 text-gray-500'} />
                        <span className="text-sm text-gray-600 md:text-xs ml-1 md:mr-4">
                            {Utils.comma(commentsCount)}
                        </span>
                    </div>
                </a>
            }
            {/* pinned post details end */}

            {isPinned == false &&
                <a
                    className="flex flex-col w-full max-w-[calc(100vw-30px)] md:max-w-full rounded-md p-2 bg-slate-50 hover:bg-purple-200/30"
                    onClick={(e) => { e.stopPropagation(); navigate(`/community/${community._id}/forum/${forum._id}/post/${postId}`) }}
                >
                    <div className="text-xs mb-2">
                        Latest Post By :
                        <a
                            className="text-purple-800 text-underline pl-1 cursor-pointer"
                            onClick={(e) => { e.stopPropagation(); navigate(`/profile/${byId}`) }} >
                            {by}
                        </a>
                        <span className="px-2">•</span>
                        <span className="text-xs ml-2x text-gray-600 pt-1 md:pt-0">
                            {/* {postDate} */}
                            <ReactTimeAgo date={postDate} locale="en-US" />
                        </span>
                    </div>
                    <div className="flex flex-row w-full max-w-full md:truncate text-sm text-purple-900 font-bold">
                        {isPinned ? forum?.pinned_post?.title : forum?.latest_post?.title}
                    </div>
                    <div className="line-clamp-3 w-full max-w-full  " dangerouslySetInnerHTML={{ __html: getCleanHtml(isPinned ? (forum.pinned_post?.details ?? "") : (forum.latest_post?.details ?? "")) }}></div>
                    {(media && media.length > 0) &&
                        <div className="pt-2">
                            <img
                                className="inline-block   w-full max-w-full rounded-md "
                                src={media}
                                alt=""
                            />
                        </div>
                    }
                    <div className="flex flex-row items-center pt-2 text-[#6b7280]">
                        <LikeIcon className={'text-gray-200 md:w-3'} />
                        <span className="text-sm md:text-xs ml-1 mr-4 md:mr-2 text-gray-600">
                            {Utils.comma(likesCount)}
                        </span>
                        <ChatsIcon fillColor={'#6b7280'} strokeColor={'#ffffff'} className={'w-5 md:w-4 text-gray-500'} />
                        <span className="text-sm text-gray-600 md:text-xs ml-1 md:mr-4">
                            {Utils.comma(commentsCount)}
                        </span>
                    </div>
                </a>
            }
        </>
    )
};

export default PostPreview;
