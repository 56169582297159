//CDC, FDA
import { Utils } from "../../utils/index"

const RssFeedsLimit = 5;

// export const sources = [
//     {
//         name: "Centers for Disease Control and Prevention (CDC)",
//         url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCiMg06DjcUk5FRiM3g5sqoQ",
//         get: (rssFeeds) => {
//             let entries = rssFeeds?.feed?.entry ?? [];
//             Utils.shuffle(entries);
//             let list = [];
//             for (let index = 0; index < entries.length; index++) {
//                 const entry = entries[index];
//                 const link = entry?.link?._attributes?.href ?? "";
//                 const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
//                 const author = entry?.author?.name?._text ?? "";
//                 const title = entry?.title?._text ?? "";
//                 const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
//                 list.push({
//                     link,
//                     thumbnail_url,
//                     author,
//                     title,
//                     publish_date
//                 });
//                 if (list.length == RssFeedsLimit) {
//                     break;
//                 }
//             }
//             return list
//         }
//     }, {
//         name: "American Heart Association",
//         url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCLiWQk8JzhNRcNiDKk4dpaw",
//         get: (rssFeeds) => {
//             // console.log("rssFeeds", rssFeeds);
//             let entries = rssFeeds?.feed?.entry ?? [];
//             Utils.shuffle(entries);
//             let list = [];
//             for (let index = 0; index < entries.length; index++) {
//                 const entry = entries[index];
//                 const link = entry?.link?._attributes?.href ?? "";
//                 const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
//                 const author = entry?.author?.name?._text ?? "";
//                 const title = entry?.title?._text ?? "";
//                 const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
//                 list.push({
//                     link,
//                     thumbnail_url,
//                     author,
//                     title,
//                     publish_date
//                 });
//                 if (list.length == RssFeedsLimit) {
//                     break;
//                 }
//             }
//             return list
//         }
//     }, {
//         name: "Johns Hopkins Medicine",
//         url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCATNzbTbfeoMhNonZGZmrhA",
//         get: (rssFeeds) => {
//             let entries = rssFeeds?.feed?.entry ?? [];
//             Utils.shuffle(entries);
//             let list = [];
//             for (let index = 0; index < entries.length; index++) {
//                 const entry = entries[index];
//                 const link = entry?.link?._attributes?.href ?? "";
//                 const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
//                 const author = entry?.author?.name?._text ?? "";
//                 const title = entry?.title?._text ?? "";
//                 const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
//                 list.push({
//                     link,
//                     thumbnail_url,
//                     author,
//                     title,
//                     publish_date
//                 });
//                 if (list.length == RssFeedsLimit) {
//                     break;
//                 }
//             }
//             return list
//         }
//     }, {
//         name: "Cleveland Clinic",
//         url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCxyiSz4m161Z6frOsFxJpgw",
//         get: (rssFeeds) => {
//             let entries = rssFeeds?.feed?.entry ?? [];
//             Utils.shuffle(entries);
//             let list = [];
//             for (let index = 0; index < entries.length; index++) {
//                 const entry = entries[index];
//                 const link = entry?.link?._attributes?.href ?? "";
//                 const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
//                 const author = entry?.author?.name?._text ?? "";
//                 const title = entry?.title?._text ?? "";
//                 const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
//                 list.push({
//                     link,
//                     thumbnail_url,
//                     author,
//                     title,
//                     publish_date
//                 });
//                 if (list.length == RssFeedsLimit) {
//                     break;
//                 }
//             }
//             return list
//         }
//     }];

export const sources = [
    // place holder on
    {
        name: "Mayo Clinic health information",
        url: "https://www.mayoclinic.org/rss/all-health-information-topics",
        get: (rssFeeds) => {
            // let entries = rssFeeds?.feed?.entry ?? [];
            // Utils.shuffle(entries);
            // let list = [];
            // for (let index = 0; index < entries.length; index++) {
            //     const entry = entries[index];
            //     const link = entry?.link?._attributes?.href ?? "";
            //     const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
            //     const author = entry?.author?.name?._text ?? "";
            //     const title = entry?.title?._text ?? "";
            //     const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
            //     list.push({
            //         link,
            //         thumbnail_url,
            //         author,
            //         title,
            //         publish_date
            //     });
            //     if (list.length == RssFeedsLimit) {
            //         break;
            //     }
            // }

            let list = [{
                link: "https://www.medpagetoday.com/painmanagement/painmanagement/114021",
                thumbnail_url: "https://clf1.medpagetoday.com/media/images/114xxx/114021.jpg",
                author: "MedPage Today Anesthesiology",
                title: `FDA Approves Novel Pain Pill Without Addiction Risk`,
                publish_date: "Fri, 31 Jan 2025 09:19:32 -0500"
            },{
                link: "https://www.medpagetoday.com/neurology/painmanagement/113965",
                thumbnail_url: "https://clf1.medpagetoday.com/media/images/113xxx/113965.jpg",
                author: "MedPage Today Anesthesiology",
                title: `Novel Biomarkers Predict Pain Sensitivity`,
                publish_date: "Mon, 27 Jan 2025 17:17:46 -0500"
            },
            {
                link: "https://www.medpagetoday.com/opinion/unchartedterritory/113550",
                thumbnail_url: "https://clf1.medpagetoday.com/media/images/113xxx/113550.jpg",
                author: "MedPage Today Anesthesiology",
                title: `To Britain on Legalizing Assisted Dying: Proceed With Caution`,
                publish_date: "Thu, 26 Dec 2024 14:00:00 -0500"
            },
            {
                link: "",
                thumbnail_url: "https://clf1.medpagetoday.com/media/images/114xxx/114021.jpg",
                author: "MedPage Today Anesthesiology",
                title: `Anthem Blue Cross Blue Shield Reverses Decision to Put a Time Limit on Anesthesia`,
                publish_date: "Fri, 31 Jan 2025 09:19:32 -0500"
            }];
            return list
        }
    }
];