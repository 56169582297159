export const Utils = {
    chaeckPasswordStrength(val, errorMessage = "") {

        if (errorMessage === "") {
            errorMessage = "Password should have at least: 8 characters including, 1 upper case, 1 special character (!@#$&*), and 1 number.";
        }
        if (typeof val != 'string') {
            return errorMessage;
        }
        if ((val = val.trim()).length == 0) {
            return errorMessage;
        }


        // 8 characters length
        // 1 characters in Upper Case
        // 1 Special Character (!@#$&*)
        // 1 numerals (0-9)
        var res = val.match(
            /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8}/
        );
        if (res) {
            return true;
        } else {
            return errorMessage;
        }
    },
    bigNumberFormat(n, d) {
        //https://stackoverflow.com/questions/9345136/1000000-to-1m-and-1000-to-1k-and-so-on-in-js
        let x = ('' + n).length;
        let p = Math.pow;
        d = p(10, d);
        x -= x % 3;
        return (Math.round(n * d / p(10, x)) / d) + " kMGTPE"[x / 3];
    },
    comma(val) {
        //https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
        var t = parseFloat(val);
        var bt = t.toLocaleString();
        return bt;
    },
    formatDate(date, format) {
        if (!date || (typeof date == 'string' && date == "")) {
            return date;
        }
        if (typeof date == 'string') {
            date = new Date(date);
        }
        const shortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let monthIndex = date.getMonth();
        let dayStr = date.getDate();
        if (dayStr < 10) {
            dayStr = `0${dayStr}`;
        } else {
            dayStr = `${dayStr}`;
        }
        let montStr = "";
        if (monthIndex < 10) {
            montStr = `0${monthIndex + 1}`;
        } else {
            montStr = `${monthIndex + 1}`;
        }

        let hrStr = date.getHours();
        var ampm = "AM";
        if (hrStr > 12) {
            hrStr -= 12;
            ampm = "PM";
        }
        if (hrStr < 10) {
            hrStr = `0${hrStr}`;
        } else {
            hrStr = `${hrStr}`;
        }
        let minStr = date.getMinutes();
        if (minStr < 10) {
            minStr = `0${minStr}`;
        } else {
            minStr = `${minStr}`;
        }

        if (format === "month date, year") {
            return `${shortNames[monthIndex]} ${dayStr},  ${date.getFullYear()}`;
        } else if (format === "month date, year hrs:mins") {
            return `${shortNames[monthIndex]} ${dayStr},  ${date.getFullYear()} ${hrStr}:${minStr} ${ampm}`;
        } else if (format === "mm-dd-yy") {
            return `${montStr}-${dayStr}-${date.getFullYear()}`;
        } else if (format === "mm-dd-yy hrs:mins") {
            return `${montStr}-${dayStr}-${date.getFullYear()} ${hrStr}:${minStr} ${ampm}`;
        }
        return date.toDateString();
    },
    getLastWeeksDate() {
        //https://bobbyhadz.com/blog/javascript-get-last-week-date
        const now = new Date();

        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7 //supposed to be 7 
        );
    },
    isLessThanMonth(date, fromDate) {
        const itemDate = typeof date === 'string' ? new Date(date) : date;
        fromDate = !fromDate ? new Date() : fromDate;
        // const acceptedTimeSpan = 30 * 24 * 60 * 60 * 1000 ; //todo: 30 days
        // const acceptedTimeSpan = 30 * 24 * 60 * 60 * 1000 * 2; //two months
        const acceptedTimeSpan = 7 * 24 * 60 * 60 * 1000; //weeks time
        let timeDiffInMs = fromDate.getTime() - itemDate.getTime();
        if (timeDiffInMs < 0) {
            //the item date is greater, newer
            //check if its within a week
            const nowTime = (new Date()).getTime();
            timeDiffInMs = nowTime - itemDate.getTime(); //makes sure that things are within a weeks time
            if (timeDiffInMs <= acceptedTimeSpan) {
                return true;
            }
            return false;
        }

        return false;
    },
    getForum(communities, comid, id) {
        let forum = null;
        let breadcrumbPages = [
            { name: 'Forums', href: '/dashboard', current: false },
        ];
        for (let index = 0; index < communities.length; index++) {
            const community = communities[index];
            if (community.id === comid && community.forums) {
                breadcrumbPages.push(
                    { name: community.title, href: '#', current: false }
                );
                for (let j = 0; j < community.forums.length; j++) {
                    const tempForum = community.forums[j];
                    if (tempForum.id === id) {
                        forum = tempForum;
                        break;
                    }
                }
                break;
            }
        }

        return {
            forum,
            breadcrumbPages
        };
    },
    getPost(communities, comid, id, pid) {
        let forumRes = this.getForum(communities, comid, id);
        let breadcrumbPages = forumRes.breadcrumbPages;
        breadcrumbPages[1].href = `/community/${comid}/forum/${id}`;
        let forum = forumRes.forum;
        let post = null;
        for (let index = 0; index < forum.posts.length; index++) {
            const tempPost = forum.posts[index];
            if (tempPost.id === pid) {
                breadcrumbPages.push(
                    { name: forum.title, href: '#', current: true }
                );
                post = tempPost;
                break;
            }
        }
        return {
            forum,
            breadcrumbPages,
            post
        };
    },
    getJob(jobsList, id) {
        let job = null;
        for (let index = 0; index < jobsList.length; index++) {
            const jobItem = jobsList[index];
            if (parseInt(jobItem.id) === parseInt(id)) {
                job = { ...jobItem };
                break;
            }
        }

        return job;
    },
    insertBreaks(text, interval) {
        return text.split("").reverse().reduce((acc, x) => {
            if (acc[acc.length - 1].length < interval) {
                acc[acc.length - 1] += x;
            } else {
                acc.push(x);
            }
            return acc;
        }, [""]).reduce((acc, seg) => {
            acc.push(seg.split("").reverse().join(""));
            return acc;
        }, []).reverse().join("<wbr/>");
    },
    shuffle(array) {
        let currentIndex = array.length;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
    }
};