const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

export const setEndPoint = (method = "GET", smag = "/", isSecured = false) => {
    return { secured: isSecured, endPoint: smag, method: method};
}

const AUTH_API = "/auth";
export const registerEndPoint = setEndPoint(POST, `${AUTH_API}/register`);
export const accountActivateEndPoint = setEndPoint(PUT, `${AUTH_API}/activate`);
export const resendAccountActivationCodeEndPoint = setEndPoint(PUT, `${AUTH_API}/resend`);
export const loginEndPoint = setEndPoint(POST, `${AUTH_API}/login`);
export const logoutEndPoint = setEndPoint(DELETE, `${AUTH_API}/logout`, true);
export const registerViaGoogleEndPoint = setEndPoint(POST, `${AUTH_API}/google/register`);
export const loginViaGoogleEndPoint = setEndPoint(POST, `${AUTH_API}/google/login`);
export const forgotPasswordEndPoint = setEndPoint(POST, `${AUTH_API}/forgot-password`);
export const resetPasswordEndPoint = setEndPoint(PUT, `${AUTH_API}/reset-password`);
export const resendResetPasswordCodeEndPoint = setEndPoint(PUT, `${AUTH_API}/resend-reset-code`);
export const changeEmailEndPoint = setEndPoint(PUT, `${AUTH_API}/change-email`, true);
export const verifyEmailEndPoint = setEndPoint(PUT, `${AUTH_API}/verify-new-email`);
export const resendVerifyNewCodeEndPoint = setEndPoint(PUT, `${AUTH_API}/resend-verity-new-code`);
export const updatePasswordEndPoint = setEndPoint(PUT, `${AUTH_API}/update-password`, true);
export const updateProfileEndPoint = setEndPoint(PUT, `/profile/update`, true);
export const getProfileEndPoint = setEndPoint(GET, `/profile`, true);
export const getPublicProfileEndPoint = (id) => setEndPoint(GET, `/profile/public/${id}`);

export const getJobsBudgesLastTimeEndPoint = setEndPoint(GET, `/users/budges-time/jobs`, true);
export const updateJobsBudgesLastTimeEndPoint = setEndPoint(PUT, `/users/budges-time/jobs`, true);
export const getCommunitiesBudgesEndPoint = setEndPoint(GET, `/users/budges-time/communities`, true);
export const getAnonymousBudgesEndPoint = setEndPoint(POST, `/users/budges-time/anonymous-communities`);
export const updateCommunitiesBudgesEndPoint = setEndPoint(PUT, `/users/budges-time/communities`, true);

const INVITE_API = "/invite";
export const inviteEndPoint = setEndPoint(POST, `${INVITE_API}/invite-user`, true);
export const verifyInviteLinkParamsEndPoint = setEndPoint(POST, `${INVITE_API}/verify-invite-params`);
export const acceptInvitationEndPoint = setEndPoint(POST, `${INVITE_API}/accept-invitation`);
export const generateShareLinkEndPoint = setEndPoint(PUT, `${INVITE_API}/generate-public-share-link`, true);
export const joinViaLinkEndPoint = setEndPoint(POST, `${INVITE_API}/join-via-link`);
export const verifyJoinLinkParamsEndPoint = setEndPoint(POST, `${INVITE_API}/verify-join-link-params`);
export const leaderboardEndPoint = setEndPoint(GET, `${INVITE_API}/leader-board`);
export const activityboardEndPoint = setEndPoint(GET, `${INVITE_API}/activity-leaders`);

const NEWSFEED_API = "/newsfeed";
export const getNewsFeedEndPoint =  (page) => setEndPoint(GET, `${NEWSFEED_API}?page=${page}`);

const COMMUNITIES_API = "/communities";
export const getCommunitiesEndPoint = setEndPoint(GET, `${COMMUNITIES_API}/active`);
export const getAllCommunitiesEndPoint = setEndPoint(GET, `${COMMUNITIES_API}/all`, true);
export const getForumDetailsEndPoint = (id, order="desc") => setEndPoint(GET, `${COMMUNITIES_API}/forums/view/${id}?order=${order}`);
export const addCommunityRequestEndPoint = setEndPoint(POST, `/community/requests/add`, true);
export const getCommunityRequestsEndPoint = setEndPoint(GET, `/community/requests`, true);
export const searchCommunityRequestsEndPoint = setEndPoint(POST, `/community/requests/search`, true);
export const rejectCommunityRequestsEndPoint = setEndPoint(PUT, `/community/requests/reject`, true);
export const acceptCommunityRequestsEndPoint = setEndPoint(PUT, `/community/requests/accept`, true);
export const getCommunityEndPoint = (id) => setEndPoint(GET, `${COMMUNITIES_API}/${id}`, true);
export const updateCommunityEndPoint = setEndPoint(PUT, `${COMMUNITIES_API}/update`, true);
export const deleteCommunityEndPoint = setEndPoint(DELETE, `${COMMUNITIES_API}/delete`, true);
export const addFormumEndPoint = setEndPoint(POST, `${COMMUNITIES_API}/forums/add`, true);
export const updateForumEndPoint = setEndPoint(PUT, `${COMMUNITIES_API}/forums/update`, true);
export const deleteForumEndPoint = setEndPoint(DELETE, `${COMMUNITIES_API}/forums/delete`, true);
export const pinCommunityEndPoint = setEndPoint(PUT, `${COMMUNITIES_API}/pin`, true);
export const unPinCommunityEndPoint = setEndPoint(PUT, `${COMMUNITIES_API}/unpin`, true);
export const searchGeneralEndPoint = setEndPoint(POST, `${COMMUNITIES_API}/search`);


const POSTS_API = "/posts";
export const getPostDetailsEndPoint = (id, order="desc") => setEndPoint(GET, `${POSTS_API}/view/${id}?order=${order}`);
export const addPostEndPoint = setEndPoint(POST, `${POSTS_API}/add`, true);
export const updatePostEndPoint = setEndPoint(PUT, `${POSTS_API}/update`, true);
export const deletePostEndPoint = setEndPoint(DELETE, `${POSTS_API}/delete`, true);
export const likePostEndPoint = setEndPoint(POST, `${POSTS_API}/like`, true);
export const postCommentEndPoint = setEndPoint(POST, `/replies/add`, true);
export const likeCommentEndPoint = setEndPoint(POST, `/replies/like`, true);
export const updateCommentEndPoint = setEndPoint(PUT, `/replies/update`, true);
export const deleteCommentEndPoint = setEndPoint(DELETE, `/replies/delete`, true);
export const pinPostEndPoint = setEndPoint(PUT, `${POSTS_API}/pin`, true);
export const unPinPostEndPoint = setEndPoint(PUT, `${POSTS_API}/unpin`, true);

const JOBLISTING_API = "/joblistings";
export const getActiveJobsEndPoint = setEndPoint(GET, `${JOBLISTING_API}/active`);
export const getAllJobsEndPoint = setEndPoint(GET, `${JOBLISTING_API}/all`);
export const getOrganisationJobsEndPoint = setEndPoint(GET, `${JOBLISTING_API}/org`, true)
export const viewJobDetailsEndPoint = (id) => setEndPoint(GET, `${JOBLISTING_API}/jobs/view/${id}`, true);
export const getMyCompaniesEndPoint = setEndPoint(GET, `${JOBLISTING_API}/companies/my`, true);
export const addJobEndPoint = setEndPoint(POST, `${JOBLISTING_API}/jobs/add`, true);
export const trackApplyClickEndPoint = setEndPoint(POST, `${JOBLISTING_API}/jobs/apply`, true);
export const checkIsJobSavedEndPoint = (id) => setEndPoint(GET, `${JOBLISTING_API}/jobs/issaved/${id}`, true);
export const editJobEndPoint = setEndPoint(PUT, `${JOBLISTING_API}/jobs/update`, true);
export const searchJobEndPoint = setEndPoint(POST, `${JOBLISTING_API}/jobs/search/all`, true);
export const searchOrganisationJobEndPoint = setEndPoint(POST, `${JOBLISTING_API}/jobs/search/all`, true);
export const saveJobEndPoint = setEndPoint(POST, `${JOBLISTING_API}/jobs/save`, true);
export const unSaveJobEndPoint = setEndPoint(POST, `${JOBLISTING_API}/jobs/unsave`, true);

const SUPPORT_API = "/support";
export const getSupportTicketsEndPoint = setEndPoint(GET, `${SUPPORT_API}/tickets`, true);
export const addSupportTicketEndPoint = setEndPoint(POST, `${SUPPORT_API}/ticket`, true);
export const getSupportTicketDetailsEndPoint = (id) =>  setEndPoint(GET, `${SUPPORT_API}/ticket/${id}`, true);
export const respondToSupportTicketEndPoint = setEndPoint(POST, `${SUPPORT_API}/chat`, true);

const FORUM_ADS_API = "/forumads";
export const addForumAdEndPoint = setEndPoint(POST, `${FORUM_ADS_API}/add`, true);

const EVENTS_API = "/events";
export const eventsEndPoint = setEndPoint(GET, `${EVENTS_API}/all`, true);
export const addEventEndPoint = setEndPoint(POST, `${EVENTS_API}/add`, true);
export const editEventEndPoint = setEndPoint(PUT, `${EVENTS_API}/update`, true);
export const deleteEventEndPoint = setEndPoint(DELETE, `${EVENTS_API}/delete`, true);
export const getEventDetailsEndPoint = (id) => setEndPoint(GET, `${EVENTS_API}/view/${id}`, true);
export const searchEventsEndPoint = setEndPoint(POST, `${EVENTS_API}/search/all`, true);
export const searchOrganisationEventsEndPoint = setEndPoint(POST, `${EVENTS_API}/search/all`, true);

const CHAT_API = "/chats";
export const getUsersChatsListEndPoint = (id) => setEndPoint(GET, `${CHAT_API}/list${id.length > 0? '?with=' + id : ''}`, true);
export const getUsersChatWithAnotherEndPoint = (id) => setEndPoint(GET, `${CHAT_API}/with/${id}`, true);
export const searchUsersChatsListEndPoint = setEndPoint(POST, `${CHAT_API}/search`, true);
export const sendChatMessageEndPoint = setEndPoint(POST, `${CHAT_API}/send`, true);

